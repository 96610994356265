import { AlimApi } from "api/AlimApi";
import CalendarInput from "components/common/CalendarInput";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import { _PAGE_LIMIT } from "config";
import { useEffect, useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_dateToday, cm_isEmpty, cm_resIsWrong } from "utils/common";

export default function AlimComLabList() {
    const [list, setList] = useState(null);
    const alimApi = AlimApi();
    const errDic = CommonTranslateErr();

    //pagination
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const limit = _PAGE_LIMIT;
    const handleSetPage = (page) => setPage(page);

    //search month
    const [date, setDate] = useState(cm_dateToday("YYYY-MM"));
    const onChangeDate = (date) => {
        setPage(1);
        setDate(date);
    };

    //load
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, date]);

    const handleLoad = async () => {
        let arrDate = date.split('-');

        let skip = ((page-1) * 10);
        const res = await alimApi.getAlimStatsComlab({
            year : arrDate[0],
            month : arrDate[1], 
            skip : skip,
            limit : limit
        });
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.AL_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setPage(1);
            setTotal(0);
        }
    }

    const t = useTranslate();

    return(
        <>
            <div className="tabs-search">
                <div className="title">
                    <h4>검색조건</h4>
                </div>
                <div className="search-box">
                    <div className="item">
                        <dl>
                            <dt>조회 날짜</dt>
                            <dd>
                                <div className="absoulte">
                                    <CalendarInput
                                        onChange={onChangeDate}
                                        name={"date"}
                                        value={date} 
                                        option={{viewType: "year"}}
                                        inputAddStyle={{width: "250px"}}
                                        isOn={false}
                                    />
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
            
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "20%"}}/>
                        <col style={{width: "20%"}}/>
                        <col style={{width: "20%"}}/>
                        <col style={{width: "20%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>ComLab Id</th>
                            <th>ComLab Name</th>
                            <th>Month</th>
                            <th>Hit</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(list) ?
                            list.map((comlab) => {
                                return(
                                    <tr key={`comlab_seq_${comlab.comlab_id}`}>
                                        <td>{comlab.comlab_id}</td>
                                        <td>{comlab.comlab_name}</td>
                                        <td>{comlab.month}</td>
                                        <td>{comlab.hit}</td>
                                        <td>{comlab.total}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={5}>{t("tbNoResults")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>

            <Pagination total={total} limit={limit} page={page} setPage={handleSetPage} />
        </>
    )
};


