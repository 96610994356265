import { useEffect, useState } from "react";
import { RequestApi } from "api/RequestApi";
import useInput from "hooks/useInput"
import Pagination from "components/common/Pagination";
import RequestSearch from "components/user/RequestSearch";
import { cm_dateAddDay, cm_dateMoment, cm_dateToday, cm_isEmpty, cm_resIsWrong } from "utils/common";
import { useStore } from "contexts/Store";
import { _PAGE_LIMIT } from "config";
import QRCodePrint from "components/common/QRCodePrint";
import RequestView from "components/user/RequestView";
import useTranslate from "components/language/useTranslate";
import { CommonTranslateErr } from "utils/axios";
import { Modal } from "react-bootstrap";
import RequestDeliverdModal from "components/modal/RequestDeliverdModal";
import { cl_arrFindNextTime, cl_dentalLabAlimStringToList } from "utils/commonLab";
import useInterval from "hooks/useInterval";
import { _INTERVAL_TIME_MINUTE } from "config";
import AlimRender from "components/frame/AlimRender";

export default function RequestList() {
    const t = useTranslate();
    // 기공물 접수 의뢰서 현황 컴포넌트
    const store = useStore();
    const {CO_MAP} = store;
    const errDic = CommonTranslateErr();

    //reLoad
    const [reLoad, setReLoad] = useState(false);
    const handleReLoadAndPageInit = (pageInit=false) => {
        setList(null);
        if(pageInit){
            handleSetPage(1);
        }else{
            handleSetPage(page);
        }
        setReLoad(!reLoad);
    }

    // search
    const [search, searchChange, searchInit] = useInput({
        labdent_id : 0,
        date_from : cm_dateMoment(cm_dateAddDay(new Date(), -6)),
        date_to : cm_dateToday(),
        query_str : "",
        req_flag : 1,
    });

    //pagination
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(_PAGE_LIMIT);
    
    const handleSetPage = (page) => setPage(page);

    const handleLimit = (e) => {
        setLimit(e.target.value);
        handleReLoadAndPageInit(true);
    }

    // load
    const [list, setList] = useState(null); 
    const requestApi = RequestApi();

    useEffect(() => {
        handleLoad();
        handleQRPrintTodayRegister();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, reLoad]);

    const handleLoad = async () => {
        let skip = ((page-1) * 10);
        const res = await requestApi.getRequestQuery({
            labdent_id : search.labdent_id,
            date_from : search.date_from,
            date_to : search.date_to,
            query_str : search.query_str,
            skip : skip, 
            limit : limit,
            req_flag : search.req_flag
        });
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.RQ_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    const [qrlist, setQrlist] = useState([]);
    const handleQRPrintTodayRegister = async () => {
        const res = await requestApi.getRequestQuery({
            labdent_id : 0,
            date_from : cm_dateToday(),
            date_to : cm_dateToday(),
            query_str : "",
            skip : 0,
            limit : 0,
            req_flag : 1 //접수일
        });
        if(!cm_resIsWrong(res, errDic)){
            setQrlist(res.data.RQ_LIST);
        }
    }

    // check request. using delivered, QR Code Print, etc.
    const [tempRequestList, setTempRequestList] = useState([]);

    // page, search 변경 등시 초기화
    useEffect(() => {
        setTempRequestList([]);
    },[page, reLoad])

    const handleSelectRequest = (request) => {
        // check list 사용시
        let newTempRequestList = [...tempRequestList];
        let find = newTempRequestList.find(x => x.id === request.id);
        if(find){
            newTempRequestList = newTempRequestList.filter(x => x.id !== request.id);
        }else{
            newTempRequestList.push(request);
        }
        setTempRequestList(newTempRequestList);
    }

    const [modalType, setModalType] = useState(null);

    //delivered And AlimTalk modal
    const handleDelivered = (modalType) => {
        setModalType(modalType);
        handleModal();
    }

    //alimTime
    const [alimTime, alimTimeChange, alimTimeInit] = useInput({
        date : cm_dateToday(),
        time : "",
    })

    useEffect(() => {
        if(!cm_isEmpty(CO_MAP) && !cm_isEmpty(CO_MAP.alim_time_list)){
            // 현재 "시간" 기준 다음 time을 찾음
            handleInitAlimTime(CO_MAP.alim_time_list);
        }
    },[CO_MAP]);

    const handleInitAlimTime = (alimTimeStr) => {
        let alimTimeList = cl_dentalLabAlimStringToList(alimTimeStr);
        let nextTime = cl_arrFindNextTime(alimTimeList);
        alimTimeInit("REPLACE_AS", nextTime, "time");
    };

    useInterval(() => {
        if(!cm_isEmpty(CO_MAP.alim_time_list)){
            handleInitAlimTime(CO_MAP.alim_time_list);
        }
    }, _INTERVAL_TIME_MINUTE)

    // request delivered modal
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => {
        setIsOpen((prevValue) => !prevValue);
    }

    //TODO 필터 컴포넌트 추가 예정 (발송상태 필터, 리메이크 필터)

    return(
        <>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                <RequestSearch search={search} searchChange={searchChange} searchInit={searchInit} handleReLoadAndPageInit={handleReLoadAndPageInit}/>

                <div className="flex justify-content-between align-items-center mb10">
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        {/* 발송처리 */}
                        <button className="btn btn-line-blue" onClick={() => handleDelivered("delivered")} disabled={cm_isEmpty(tempRequestList)}>
                            <p>
                                <span><i style={{ scale: "1.5", color: "#4C90F6", marginRight: "10px" }} className="xi-truck"></i></span>
                                <span>{t("stShipping")}</span>
                            </p>
                        </button>
                        {/* 알림수정 */}
                        <AlimRender>
                            <button className="btn btn-line-blue" onClick={() => handleDelivered("alimTalk")} disabled={cm_isEmpty(tempRequestList)}>
                                <p>
                                    <span><i style={{ scale: "1.5", color: "#4C90F6", marginRight: "10px" }} className="xi-send"></i></span>
                                    <span>{t("stAlimEdit")}</span>
                                </p>
                            </button>
                        </AlimRender>
                        {/* 선택 주문 QR프린트 */}
                        <QRCodePrint requestList={tempRequestList}>
                            <button className="btn btn-line-blue" disabled={cm_isEmpty(tempRequestList)}>
                                <p>
                                    <span><i style={{ scale: "1.5", color: "#4C90F6", marginRight: "10px" }} className="xi-qr-code"></i></span>
                                    <span>{t("viSelectedOrder")}</span>
                                </p>
                            </button>
                        </QRCodePrint>
                        {/* 금일 접수 QR프린트 */}
                        <QRCodePrint requestList={qrlist}>
                            <button className="btn btn-line-blue" disabled={cm_isEmpty(qrlist)}>
                                <p>
                                    <span><i style={{ scale: "1.5", color: "#4C90F6", marginRight: "10px" }} className="xi-qr-code"></i></span>
                                    <span>{t("viTodayRegistration")}</span>
                                </p>
                            </button>
                        </QRCodePrint>
                    </div>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Pagination total={total} limit={limit} page={page} setPage={handleSetPage} type="short" />
                        <select className="select" onChange={handleLimit}>
                            <option value={10}>{t("vi10See")}</option>
                            <option value={20}>{t("vi20See")}</option>
                            <option value={30}>{t("vi30See")}</option>
                        </select>
                    </div>
                </div>
                <div>
                    <RequestView 
                        list={list} 
                        handleReLoadAndPageInit={handleReLoadAndPageInit} 
                        upperReLoad={setReLoad}
                        tempRequestList={tempRequestList}
                        setTempRequestList={setTempRequestList}
                        handleSelectRequest={handleSelectRequest}
                    />
                </div>
            </div>
            <Modal show={isOpen} onHide={handleModal} centered>
                <RequestDeliverdModal isOpen={isOpen} handleModal={handleModal}
                    modalType={modalType}
                    alimTime={alimTime} alimTimeChange={alimTimeChange} alimTimeInit={alimTimeInit}
                    tempRequestList={tempRequestList} setTempRequestList={setTempRequestList} upperReLoad={setReLoad}
                    handleInitAlimTime={handleInitAlimTime}
                /> 
            </Modal>
        </>
    )
}