import { ProdSaleApi } from "api/ProdSaleApi";
import { ProdSaleHistoryApi } from "api/ProdSaleHistoryApi";
import useTranslate from "components/language/useTranslate";
import { useStore } from "contexts/Store";
import { useEffect, useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_currMax, cm_isEmpty, cm_numComma, cm_numCurr, cm_removeNumComma, cm_resIsWrong, cm_swAlert } from "utils/common";
import { cl_getValue, cl_prodTypeUnitName } from "utils/commonLab";

export default function ProductLabDentEditModal({isOpen, handleModal, selectProdSale, selectProdSaleChange, handleReLoad}){
    const prodSaleApi = ProdSaleApi();
    const errDic = CommonTranslateErr();

    //get Labdent name
    const store = useStore();
    const {LD_LIST, US_LIST} = store;
    let labdentName = "";
    let fine = LD_LIST.find(x => x.id === selectProdSale.labdent_id);
    if(fine) labdentName = fine.name;

    //final price view
    const [finalPrice, setFinalPrice] = useState(()=> {
        const {price, prodSale_sale_price} = selectProdSale;
        return price + Number(prodSale_sale_price)
    },[]);

    //edit
    const [edit] = useState(() => selectProdSale.prodSale_id > 0 ? true : false);

    const handleChangeNumber = (e) => {
        const newProdSalePrice = cm_removeNumComma(e.target.value);
        e.target.value = newProdSalePrice;
        selectProdSaleChange(e);

        //finalPrice set
        const newFinalPrice = selectProdSale.price + Number(newProdSalePrice);
        setFinalPrice(newFinalPrice);
    }

    //save
    const onCreate = async () => {
        let createData = {
            prod_id: selectProdSale.prod_id,
            labdent_id: selectProdSale.labdent_id,
            comlab_id: selectProdSale.comlab_id,
            sale_price: selectProdSale.prodSale_sale_price,
        }

        const res = await prodSaleApi.postProdSale({data: createData});

        if(!cm_resIsWrong(res, errDic)){
            cm_swAlert(t("atSuccess"), "info", t("evConfirm"));
            handleModal();
            handleReLoad();
        }
    }

    const onEdit = async () => {
        let editData = {
            comlab_id: selectProdSale.comlab_id,
            labdent_id: selectProdSale.labdent_id,
            sale_price: selectProdSale.prodSale_sale_price,
            is_active: selectProdSale.prodSale_is_active,
        }

        const res = await prodSaleApi.putProdSaleId({
            prodsale_id : selectProdSale.prodSale_id,
            data : editData
        });
        if(!cm_resIsWrong(res, errDic)){
            cm_swAlert(t("atSuccess"), "info", t("evConfirm"));
            handleModal();
            handleReLoad();
        }
    }

    const handleSave = () => {
        // prodSale_id 없으면 추가, 있으면 수정.
        if(!saveValidate()) return;
        if(edit){
            onEdit();
        }else{
            onCreate();
        }
    }

    function saveValidate(){
        const {price, prodSale_sale_price} = selectProdSale;
        let result = true;

        const sumPrice = price + Number(prodSale_sale_price);

        if(cm_isEmpty(prodSale_sale_price)){
            result = false;
            cm_swAlert(t("atNoAdjustPrice"), "warning", t("evConfirm"));
        }else if(isNaN(Number(prodSale_sale_price))){
            result = false;
            cm_swAlert(t("atNoNumber"), "warning", t("evConfirm"));
        }else if(sumPrice < 0){
            result = false;
            cm_swAlert(t("atNoNegativeAmount"), "warning", t("evConfirm"));
        }else if(!cm_currMax(sumPrice)){
            result = false;
            cm_swAlert(t("atPriceOver"), "warning", t("evConfirm"));
        }

        return result;
    }

    const t = useTranslate();

    // Edit History
    const prodSaleHistoryApi = ProdSaleHistoryApi();
    const [prodSaleHistory, setProdSaleHistory] = useState([]);

    useEffect(() => {
        handleLoadSaleHistory();
    },[])

    const handleLoadSaleHistory = async () => {
        if(selectProdSale.prodSale_id <= 0 ) return;
        const res = await prodSaleHistoryApi.getProdSaleHistoryByProdSaleId({
            prodsale_id : selectProdSale.prodSale_id,
            skip : 0,
            limit : 0
        })
        if(!cm_resIsWrong(res, errDic)){
            setProdSaleHistory(res.data.PH_LIST);
        }
    }

    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" style={{minWidth:"50vw"}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">
                            {t("stDentalProsEdit")}<br/>
                            <span style={{fontSize:"17px", color:"blue"}}>{labdentName}</span>
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body" style={{overflow:"auto"}}>
                        <div style={{display:"flex", gap:"30px"}}>
                            <div style={{flex:"0 0 auto", minWidth:"300px", maxHeight: "50vh", overflow:"auto"}}>
                                <h4 className="form-tit">{t("viDetails")}</h4>
                                <div className="form-div">
                                    <input type="text" className="inp block" name="name" value={selectProdSale.name} disabled/>
                                </div>
                                <h4 className="form-tit">{t("viUnit")}</h4>
                                <div className="form-div">
                                    <input type="text" className="inp block" name="prod_type" value={cl_prodTypeUnitName(selectProdSale.prod_type)} disabled/>
                                </div>
                                <h4 className="form-tit">{t("slStandardPrice")}</h4>
                                <div className="form-div">
                                    <input type="text" className="inp block" name="price" value={cm_numCurr(selectProdSale.price) || 0} disabled/>
                                </div>
                                <h4 className="form-tit">{t("slAdjustedPrice")}</h4>
                                <div className="form-div">
                                    <input type="text" className="inp block" name="prodSale_sale_price" value={cm_numComma(selectProdSale.prodSale_sale_price)} onChange={handleChangeNumber} maxLength={15}/>
                                </div>
                                <h4 className="form-tit">{t("slFinalPrice")}</h4>
                                <div className="form-div">
                                    <input type="text" className="inp block" name="finalPrice" value={cm_numCurr(finalPrice) || 0} disabled/>
                                </div>
                                <h4 className="form-tit">{t("slApplicationAdjustment")}</h4>
                                <div className="form-div">
                                    <select className="select block" name="prodSale_is_active" value={selectProdSale.prodSale_is_active} onChange={selectProdSaleChange}>
                                        <option value={0}>{t("viUnused")}</option>
                                        <option value={1}>{t("viUsed")}</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{flex:"1"}}>
                                <h4 className="form-tit">{t("stEditHistory")}</h4>
                                <div className="table1 hover-type" style={{maxHeight: "50vh", overflow:"auto", fontSize:"15px"}}>
                                    <table>
                                        <colgroup>
                                            <col style={{width:"25%"}}/>
                                            <col style={{width:"25%"}}/>
                                            <col style={{width:"25%"}}/>
                                            <col style={{width:"25%"}}/>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>{t("dtDate")}</th>
                                                <th>{t("slAdjustedPrice")}</th>
                                                <th>{t("slApplicationAdjustment")}</th>
                                                <th>{t("inName")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!cm_isEmpty(prodSaleHistory) ?
                                                prodSaleHistory.map((prodSaleH) => {
                                                    const activeName = prodSaleH.is_active === 0 ? t("viUnused") : t("viUsed");
                                                    return(
                                                        <tr key={`sale_${prodSaleH.id}`}>
                                                            <td>{prodSaleH.created_at}</td>
                                                            <td>{prodSaleH.sale_price}</td>
                                                            <td>{activeName}</td>
                                                            <td>{cl_getValue(US_LIST, prodSaleH.user_id)}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr><td colSpan={4}>{t("tbNoResults")}</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-blue" disabled={prodSaleApi.pendPutProdSaleId} onClick={handleSave}>{t("evSave")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}