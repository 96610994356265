import QRCode from "qrcode.react"
import { Fragment, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { cm_isEmpty } from "utils/common";
import { cl_getToothsFromItems, cl_randomTwoNum } from "utils/commonLab";
import { useStore } from "contexts/Store";
import useTranslate from "components/language/useTranslate";
import styles from "./QRCodePrint.module.css";
import { _UNIVERSAL } from "config";
import { _FDI } from "config";

export default function QRCodePrint({requestList, children}){
    const requestRef = useRef();

    //Print
    const handleQRCodePrint = useReactToPrint({
        content: () => requestRef.current,
        pageStyle: "@media print { @page { width:200mm; height:40mm; margin: 5mm;} }",
        documentTitle: ``,
    })

    return(
        <div className="cuser-pointer">
            <div onClick={handleQRCodePrint}>
                {cm_isEmpty(children) ? 
                    <button>
                        <i style={{scale:"1.5", color:"#4C90F6"}} className="xi-qr-code"></i>
                    </button>
                    :
                    <>{children}</>
                }
            </div>
            <div style={{display:"none", border: "1px solid #4C90F6", backgroundColor:"white", padding:"10px", borderRadius:"5px"}}>
                <div ref={requestRef}>
                    {!cm_isEmpty(requestList) &&
                        requestList.map((request) => {
                            return(
                                <Fragment key={request.id}>
                                    <QRCodeStickerCard request={request} />
                                </Fragment>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function QRCodeStickerCard({request}){
    // 치과이름
    const store = useStore();
    const {LD_LIST, US_MAP} = store;
    let labDentName = "";
    let find = LD_LIST.find(x => x.id === request.labdent_id);
    if(find) labDentName = find.name;

    // create qrcod info
    const randomTwoNum = String(cl_randomTwoNum());
    const qrDataValue = randomTwoNum + request.id;
    const requestSeq = String(request.request_seq);
    const boxNum = requestSeq.slice(-3);

    const t = useTranslate();

    const [labelPrint] = useState(() => {
        let init = "ZD420";
        if(localStorage.getItem("setting_label")){
            init = localStorage.getItem("setting_label");
        }
        return init;
    });

    const notation = request.country_code > 1 ? _UNIVERSAL.notation : _FDI.notation;

    const getLabelPrintStyle = () => {
        let init = "ZD230";
        if(localStorage.getItem("setting_label")){
            init = localStorage.getItem("setting_label");
        }
        let style = styles[init];
        return style;
    }

    return(
        <div className={getLabelPrintStyle()}>
            <div className={styles.header}>
                <div className={styles.comlab_name}>
                    <p>{US_MAP.comlab_name}</p>
                </div>
                <div className={styles.dispatch_date}>
                    <p>{t("dlDispatchDate")} : {request.delivered_date}</p>
                </div>
            </div>

            <div className={styles.body}>
                <div className={styles.qrcod}>
                    <QRCode value={qrDataValue} />
                </div>
                <div className={styles.info}>
                    <p className={styles.font1}>{request.name}</p>
                    <p className={styles.font2}>{labDentName}</p>
                    <p className={styles.font2}>{t("viBoxNumber")} : {boxNum}</p>
                    {!cm_isEmpty(request.items) &&
                        <p>{cl_getToothsFromItems(request.items, 30, notation)}</p>
                    }
                </div>
            </div>
        </div>
    )
}