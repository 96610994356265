import { useCallback, useEffect } from "react";
import { useRequest, useRequestChange, useRequestInit } from "contexts/RequestContext";
import { cm_isEmpty, cm_numComma, cm_numCurr, cm_removeCurr, cm_removeNumComma } from "utils/common";
import { cl_prodTypeUnitName, cl_getToothText, cl_convertToothString, cl_prodType } from "utils/commonLab";
import useTranslate from "components/language/useTranslate";
import { _UNIVERSAL } from "config";
import { _FDI } from "config";
import { useStore } from "contexts/Store";
import useInput from "hooks/useInput";

export default function RequestItemList() {
    const request = useRequest();
    const requestInit = useRequestInit();

    const handleItemStatus = (idx, e) => {
        const newItems = [...request.items];

        const newItem = {...newItems[idx]};
        newItem.remake_flag = Number(e.target.value);
        newItems[idx] = newItem;

        requestInit("REPLACE_AS", newItems, "items");
    }

    const handleDeleteItem = async (id, itemType) => {
        if(itemType === "prevItem"){
            let newPrevItems = [...request.prevItems];
            let newDeletePrevItems = [...request.deletePrevItems];

            const findItem = newPrevItems.find(x => x.id === id);

            if(findItem){
                newDeletePrevItems.push(findItem);
                newPrevItems = newPrevItems.filter((x) => x.id !== id);

                requestInit("REPLACE_AS", newDeletePrevItems, "deletePrevItems");
                requestInit("REPLACE_AS", newPrevItems, "prevItems");
            }
        }else if(itemType === "newItem"){
            let newItems = [...request.items];
            newItems = newItems.filter((x, idx) => idx !== id);
    
            requestInit("REPLACE_AS", newItems, "items");
        }
    }

    return(
        <>
            <div className="aniSmoothDown">
                <div style={{display:"flex", justifyContent:"space-between", gap:"20px"}}>
                    <div style={{flex:"4"}}>
                        <RequestItemTableView handleItemStatus={handleItemStatus} handleDeleteItem={handleDeleteItem}/>
                    </div>
                    <div style={{flex:"0 0 auto"}}>
                        <RequestPrice />
                    </div>
                </div>
            </div>
        </>
    )
}

function RequestItemTableView({handleItemStatus, handleDeleteItem}){
    const store = useStore();
    const {PR_LIST} = store;
    const request = useRequest();
    const notation = request.country_code > 1 ? _UNIVERSAL : _FDI;
    const t = useTranslate();

    return(
        <div className="table1 hover-type">
            <table>
                <colgroup>
                    <col style={{width: "30%"}}/>
                    <col style={{width: "10%"}}/>
                    <col style={{width: "10%"}}/>
                    <col style={{width: "10%"}}/>
                    <col style={{width: "15%"}}/>
                    <col style={{width: "15%"}}/>
                    <col style={{width: "10%"}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th>{t("prDentalFormula")}</th>
                        <th>{t("slPricePerItem")}</th>
                        <th>{t("viUnit")}</th>
                        <th>{t("viQuantity")}</th>
                        <th>{t("slAmount")}</th>
                        <th>{t("viNote")}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {/* 기존 생성한 보철물 */}
                    {!cm_isEmpty(request.prevItems) &&
                        request.prevItems.map((item, idx) => {
                            const findProdType = cl_prodType(PR_LIST, item.prod_id);
                            let prodTypeName = "";
                            if(!cm_isEmpty(findProdType)){
                                prodTypeName = cl_prodTypeUnitName(findProdType);
                            }
                            let isRemake = (item.remake_flag > 0 ? true : false);   //리메이크(1), 리페어(2)는 삭선 표시
                            return(
                                <tr key={`prevItem_seq_${item.id}`}>
                                    <td>{item.tooth_list}</td>
                                    <td>{item.price / item.tooth_count}</td>
                                    <td>{prodTypeName}</td>
                                    <td>{item.tooth_count}</td>
                                    <td>
                                        <div className="price-box">
                                            <span className="symbol">{t("slCurrencySymbol")}</span>
                                            <span className={isRemake ? "price text-line-through" : "price"}>
                                                {cm_numComma(item.price)}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <select className="select" value={Number(item.remake_flag)} disabled>
                                            <option value={0}>{"New"}</option>
                                            <option value={1}>{"Remake"}</option>
                                            <option value={2}>{"Repair"}</option>
                                        </select>
                                    </td>
                                    {/* 기존 prevItem은 삭제시 item id를 전달함. */}
                                    <td className="cuser-pointer" onClick={() => handleDeleteItem(item.id, "prevItem")}>
                                        <i style={{scale:"1.5", color:"#ff5353"}} className="xi-close-circle-o"></i>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {/* 새로 생성한 보철물 */}
                    {!cm_isEmpty(request.items) &&
                        request.items.map((item, idx) => {
                            const tooths = cl_getToothText(item.tooth_groups, item.tooth_list, notation).join(', ');
                            const convertTooths = cl_convertToothString(tooths, notation.notation);
                            let isRemake = (item.remake_flag > 0 ? true : false);   //리메이크(1), 리페어(2)는 삭선 표시

                            return(
                                <tr key={`item_seq_${idx}`}>
                                    <td>{`${convertTooths} / ${item.prod_name}`}</td>
                                    <td>{cm_numComma(item.unit_price)}</td>
                                    <td>{cl_prodTypeUnitName(item.prod_type)}</td>
                                    <td>{item.tooth_count}</td>
                                    <td>
                                        <div className="price-box">
                                            <span className="symbol">{t("slCurrencySymbol")}</span>
                                            <span className={isRemake ? "price text-line-through" : "price"}>
                                                {cm_numComma(item.price)}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <select className="select" value={Number(item.remake_flag)} onChange={(e)=>{handleItemStatus(idx, e)}}>
                                            <option value={0}>{"New"}</option>
                                            <option value={1}>{"Remake"}</option>
                                            <option value={2}>{"Repair"}</option>
                                        </select>
                                    </td>
                                    <td className="cuser-pointer" onClick={() => handleDeleteItem(idx, "newItem")}>
                                        <i style={{scale:"1.5", color:"#ff5353"}} className="xi-close-circle-o"></i>
                                    </td>
                                </tr>
                            )
                        })
                    }

                    {/* 보철물이 없을때 */}
                    {cm_isEmpty(request.items) && cm_isEmpty(request.prevItems) &&
                        <tr><td colSpan={8}>{t("tbSelectItem")}</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

function RequestPrice() {
    const request = useRequest();
    const requestChange = useRequestChange();
    const requestInit = useRequestInit();
    const t = useTranslate();

    const getTotalPrice = useCallback(() => {
        let totalPrice = 0;

        // 신규 추가된 items 가격 계산
        if(!cm_isEmpty(request.items)){
            for(let item of request.items){
                if(item.remake_flag > 0) continue;
                totalPrice += item.price;
            }
        }

        // 기존 items 가격 계산
        if(!cm_isEmpty(request.prevItems)){
            for(let prevItem of request.prevItems){
                if(prevItem.remake_flag > 0) continue;
                totalPrice += prevItem.price;
            }
        }

        return totalPrice;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[request.items, request.prevItems]);

    let totalPrice = getTotalPrice();

    useEffect(() => {
        setFinalPrice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[request.items, request.prevItems])

    const setFinalPrice = () => {
        let adjustPrice = isNaN(Number(request.adjust_price)) ? 0 : Number(request.adjust_price);
        let finalPrice = totalPrice + adjustPrice;
        requestInit("REPLACE_AS", finalPrice, "final_price");
    }

    const handleAdjust = (e) => {
        e.target.value = cm_removeNumComma(e.target.value);

        requestChange(e);

        //조정가격 변경시 e.target.value로 final price 재계산.
        if(e.target.value === "-"){
            e.target.value = 0;
        }
        const final = totalPrice + Number(e.target.value);
        if(!isNaN(final)){
            requestInit("REPLACE_AS", final, "final_price");
        }
    }

    return(
        <>
            <div className="request-price">
                <div className="box">
                    <div>
                        <h4>{t("slTotal")}</h4>
                        <input type="text" className="inp" value={cm_numComma(totalPrice)} disabled/>
                    </div>
                    <div >
                        <h4>{t("slAdjustment")}</h4>
                        <input type="text" className="inp" name="adjust_price" value={cm_numCurr(request.adjust_price)} onChange={handleAdjust} autoComplete="off"/>
                    </div>
                    <div >
                        <h4>{t("slFinal")}</h4>
                        <input type="text" className="inp" value={cm_numCurr(request.final_price)} disabled />
                    </div>
                </div>
            </div>
        </>
    )
}