import { SuggestApi } from "api/SuggestApi";
import ContentHeader from "components/common/ContentHeader";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import { SUGGEST_STATUS } from "config";
import { SUGGEST_CATEGORY } from "config";
import { _PAGE_LIMIT } from "config";
import { useStore } from "contexts/Store";
import { useEffect, useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_resIsWrong, cm_swAlert, cm_swConfirm } from "utils/common";
import { cl_getValue } from "utils/commonLab";

export default function SuggestMng() {
    const store = useStore();
    const {US_LIST} = store;
    const [list, setList] = useState(null);

    const suggestApi = SuggestApi();
    const errDic = CommonTranslateErr();
    const t = useTranslate();

    //pagination
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(_PAGE_LIMIT);
    const handleSetPage = (page) => setPage(page);

    //reLoad
    const [reLoad, setReLoad] = useState(false);
    const handleReLoadAndPageInit = (pageInit=false) => {
        setList(null);
        if(pageInit){
            handleSetPage(1);
        }else{
            handleSetPage(page);
        }
        setReLoad(!reLoad);
    }

    const handleLimit = (e) => {
        setLimit(e.target.value);
        handleReLoadAndPageInit(true);
    }

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, reLoad]);

    const handleLoad = async () => {
        let skip = ((page-1) * 10);
        const res = await suggestApi.getSuggest({
            skip: skip,
            limit : limit
        })
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.SG_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    const [selectSuggest, setSelectSuggest] = useState(null);
    const handleChangeSuggest = (suggest) => {
        if(!cm_isEmpty(selectSuggest) && selectSuggest.id === suggest.id){
            setSelectSuggest(null);
        }else{
            setSelectSuggest(suggest);
        }
    }

    const suggestCategory = SUGGEST_CATEGORY();

    const handleDelete = async (suggest) => {
        const confirm = await cm_swConfirm(`삭제하시겠습니까?`, "", "info",t("evConfirm"), t("evCancel"));
        if(confirm){
            const res = await suggestApi.deleteSuggestId({
                suggest_id : suggest.id
            })
            if(!cm_resIsWrong(res, errDic)){
                cm_swAlert(t("atDelete"), "info", t("evConfirm"));
                setSelectSuggest(null);
                handleReLoadAndPageInit();
            }
        }
    }

    const suggestStatus =  SUGGEST_STATUS();

    const handleChangeStatus = async (suggest, e) => {
        const res = await suggestApi.putSuggestId({
            suggest_id : suggest.id,
            data : {
                flow_code : Number(e.target.value)
            }
        })
        if(!cm_resIsWrong(res, errDic)){
            setReLoad(!reLoad);
        }
    }

    return(
        <div className="aniSmoothDown">
            <ContentHeader text={("제안 목록")} />
            <div style={{display:"flex", gap:"10px", alignItems:"center", justifyContent:"end", marginBottom:"10px"}}>
                <Pagination total={total} limit={limit} page={page} setPage={handleSetPage} type="short"/>
                <select className="select" onChange={handleLimit}>
                    <option value={10}>{t("vi10See")}</option>
                    <option value={20}>{t("vi20See")}</option>
                    <option value={30}>{t("vi30See")}</option>
                </select>
            </div>
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "50%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "5%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>카테고리</th>
                            <th>제안자(UserID)</th>
                            <th>내용</th>
                            <th>확인자(UserID)</th>
                            <th>단계</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(list) ?
                            list.map((suggest) => {
                                let categoryName = "";
                                const find = suggestCategory.find(x => x.value === suggest.category_code);
                                if(find) categoryName = find.name;

                                let contents = suggest.contents;
                                if(contents.length > 60){
                                    contents = contents.slice(0, 58) + "..";
                                }

                                let customStyle = {
                                    backgroundColor : "#4C90F6",
                                    color : "white",
                                    padding : "0px 10px"
                                }

                                if(suggest.category_code > 1) customStyle.backgroundColor = "#ff5353";

                                return (
                                    <>
                                        <tr key={`suggest_seq_${suggest.id}`}>
                                            <td>{suggest.id}</td>
                                            <td>
                                                <div style={customStyle}>
                                                    {categoryName}
                                                </div>
                                            </td>
                                            <td>{cl_getValue(US_LIST, suggest.user_id)} ({suggest.user_id})</td>
                                            <td onClick={() => handleChangeSuggest(suggest)}>
                                                {contents}
                                            </td>
                                            <td>{cl_getValue(US_LIST, suggest.confirmed_id)} ({suggest.confirmed_id})</td>
                                            <td>
                                                <select className="select" value={suggest.flow_code} onChange={(e) => handleChangeStatus(suggest, e)}>
                                                    {suggestStatus.map((status) => {
                                                        return(
                                                            <option value={status.value}>{status.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                            <td className="cuser-pointer" onClick={() => handleDelete(suggest)}>
                                                <i style={{scale:"1.5", color:"#ff5353"}} className="xi-close-circle-o"></i>
                                            </td>
                                        </tr>
                                        {!cm_isEmpty(selectSuggest) && selectSuggest.id === suggest.id &&
                                            <tr style={{height:"100px"}}>
                                                <td colSpan={7}>
                                                    <div>
                                                        <textarea className="inp large-content" style={{width:"100%"}} value={suggest.contents} readOnly />
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </>
                                )
                            })
                            :
                            <tr><td colSpan={7}>{t("tbNoResults")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
};