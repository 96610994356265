import { RequestApi } from "api/RequestApi";
import ContentHeader from "components/common/ContentHeader";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import { _PAGE_LIMIT } from "config";
import { useEffect, useState } from "react";
import { CommonTranslateErr } from "utils/axios";
import { cm_isEmpty, cm_numComma, cm_resIsWrong } from "utils/common";

export default function RequestMng() {
    const [list, setList] = useState(null);
    const requestApi = RequestApi();
    const errDic = CommonTranslateErr();

    //pagination
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const limit = _PAGE_LIMIT;
    const handleSetPage = (page) => setPage(page);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page]);

    const handleLoad = async () => {
        let skip = ((page-1) * 10);
        const res = await requestApi.getRequest({
            skip : skip,
            limit : limit
        });
        if(!cm_resIsWrong(res, errDic)){
            setList(res.data.RQ_LIST);
            setTotal(res.data.LIST_TOTAL_COUNT);
        }else{
            setList([]);
            setPage(1);
            setTotal(0);
        }
    }

    const t = useTranslate();

    return(
        <div className="aniSmoothDown">
            <ContentHeader text="주문 관리"/>
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "12%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "12%"}}/>
                        <col style={{width: "12%"}}/>
                        <col style={{width: "12%"}}/>
                        <col style={{width: "12%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Request Seq</th>
                            <th>ComLab Id</th>
                            <th>Labdent Id</th>
                            <th>Doctor Name</th>
                            <th>Name</th>
                            <th>Delivered Date</th>
                            <th>Final Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(list) ?
                            list.map((request) => {
                                return(
                                    <tr key={`request_seq_${request.id}`}>
                                        <td>{request.id}</td>
                                        <td>{request.request_seq}</td>
                                        <td>{request.comlab_id}</td>
                                        <td>{request.labdent_id}</td>
                                        <td>{request.doctor_name}</td>
                                        <td>{request.name}</td>
                                        <td>{request.delivered_date}</td>
                                        <td>{cm_numComma(request.final_price)}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={8}>{t("tbNoResults")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination total={total} limit={limit} page={page} setPage={handleSetPage} />
        </div>
    )
}